// Generated by Framer (0b8b5e5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["NRLKV23Sp", "vOmcXam0_"];

const serializationHash = "framer-otu7a"

const variantClassNames = {NRLKV23Sp: "framer-v-ubevfi", vOmcXam0_: "framer-v-17egstj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {glyph: "vOmcXam0_", logo: "NRLKV23Sp"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "NRLKV23Sp"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "NRLKV23Sp", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 116, pixelWidth: 116, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/xTiEjgNCisDTzLyC0N8xl8wp8.svg"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ubevfi", className, classNames)} data-framer-name={"logo"} layoutDependency={layoutDependency} layoutId={"NRLKV23Sp"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({vOmcXam0_: {"data-framer-name": "glyph", background: {alt: "", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 500, pixelWidth: 500, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/16PEvhapNTfkIij4EwVZH0LxBc.svg"}}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-otu7a.framer-kovnoz, .framer-otu7a .framer-kovnoz { display: block; }", ".framer-otu7a.framer-ubevfi { height: 498px; overflow: hidden; position: relative; width: 498px; }", ".framer-otu7a.framer-v-17egstj.framer-ubevfi { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 498px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 498
 * @framerIntrinsicWidth 498
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"vOmcXam0_":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercELkq4czA: React.ComponentType<Props> = withCSS(Component, css, "framer-otu7a") as typeof Component;
export default FramercELkq4czA;

FramercELkq4czA.displayName = "Logo";

FramercELkq4czA.defaultProps = {height: 498, width: 498};

addPropertyControls(FramercELkq4czA, {variant: {options: ["NRLKV23Sp", "vOmcXam0_"], optionTitles: ["logo", "glyph"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramercELkq4czA, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})